import { MutationTree } from "vuex";
import { FileState } from "@/store/files/types";

export const mutations: MutationTree<FileState> = {
	FILE_PERCENTAGE(state, payload = 0) {
		state.percentage = payload;
	},
	FILE_PERCENTAGE_ALT(state, payload = 0) {
		state.percentageAlt = payload;
	}
};
