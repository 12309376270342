import { ActionTree } from "vuex";
import { FileState } from "@/store/files/types";
import { RootState } from "@/store/types";

export const actions: ActionTree<FileState, RootState> = {
	clearPercentage({ commit }) {
		commit("FILE_PERCENTAGE");
	},
	clearAltPercentage({ commit }) {
		commit("FILE_PERCENTAGE_ALT");
	}
};
