import { Module } from "vuex";
import { getters } from "@/store/files/getters";
import { actions } from "@/store/files/actions";
import { mutations } from "@/store/files/mutations";
import { FileState } from "@/store/files/types";
import { RootState } from "@/store/types";

export const state: FileState = {
	percentage: 0,
	percentageAlt: 0
};

const namespaced: boolean = true;

export const files: Module<FileState, RootState> = {
	namespaced,
	state,
	getters,
	actions,
	mutations
};
